.team {
  $host: "../../Assets/images";

  $card-width: 350px;
  $card-height: 350px;

  $thumb-height: 300px;

  $border-radius: 3px;
  $box-shadow: 0 1px 4px rgba(black, 0.3);
  $transition: cubic-bezier(0.17, 0.67, 0.5, 1.03);
  $timing-1: 0.4s 0.15s;
  $timing-2: 0.5s 0.25s;

  // $new-york-city: '#{$host}/TeamAditi.jpeg';

  @mixin pos($pos, $left: null, $top: null, $right: null, $bottom: null) {
    position: $pos;
    left: $left;

    @if $top {
      top: $top;
    }

    @if $left {
      left: $left;
    }

    @if $right {
      right: $right;
    }

    @if $bottom {
      bottom: $bottom;
    }
  }

  @mixin size($width, $height) {
    width: $width;
    height: $height;
  }
  #collapseAboutUsText {
    &.collapse:not(.show) {
      display: block;
      height: 13.3rem;
      overflow: hidden;
    }
    &.collapsing {
      height: 13.5rem;
    }
  }
  .collapse-btn {
    background: none;
    border: none;
    color: white;
    text-decoration: underline;
    width: auto;
    &.collapsed::after {
      content: "+ Show More";
    }
    &:not(.collapsed)::after {
      content: "- Show Less";
    }
  }

  a {
    position: relative;

    &,
    &:link,
    &:visited,
    &:active {
      text-decoration: none;
      padding-bottom: 3px;
      font-weight: bold;
    }

    &::after {
      content: "";
      @include pos(absolute, $left: 0, $bottom: 0);
      background: white;
      @include size(0, 1px);
      transition: 0.35s $transition;
    }

    &:hover::after {
      width: 100%;
      box-shadow: $box-shadow;
    }
  }

  .note {
    margin-top: 30px;
    color: white;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
  }

  article {
    &.card {
      // @include pos(absolute, 50%, 50%);
      @include size(auto, $card-height);
      // transform: translate(-50%, -50%) translateZ(0);
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      overflow: hidden;
      display: block;
      margin-bottom: 10px;

      .thumb {
        @include size(auto, $thumb-height);
        // background: url($new-york-city) no-repeat center;
        background-size: cover !important;
        border-radius: $border-radius;
      }

      .infos {
        @include size(auto, $card-height);
        position: relative;
        padding: 14px 24px;
        background: white;
        transition: $timing-1 $transition;
        p {
          color: #2c3531;
        }

        .title {
          position: relative;
          margin: 10px 0;
          letter-spacing: 3px;
          font-size: 1rem;
          text-transform: uppercase;
          margin-top: 0;
        }

        .desig,
        .seats {
          margin-bottom: 10px;
          text-transform: uppercase;
          font-size: 0.85rem;
        }

        .seats {
          display: inline-block;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid grey;
          opacity: 0;
          transition: $timing-2 $transition;
        }

        .txt {
          line-height: 2;
          font-size: 0.95rem;
          opacity: 0;
          transition: $timing-2 $transition;
        }

        .details {
          @include pos(absolute, $left: 0, $bottom: 0);
          margin: 10px 0;
          padding: 20px 24px;
          letter-spacing: 1px;
          font-size: 0.9rem;
          text-transform: uppercase;
          cursor: pointer;
          opacity: 0;
          transition: $timing-2 $transition;
        }
      }

      &:hover .infos {
        transform: translateY(-50px);

        .seats,
        .txt,
        .details {
          opacity: 1;
        }
      }
    }
  }
}

.icons {
  img {
    position: absolute;
    z-index: -1;
  }

  // &-triangle {
  //     top: 14%;
  //     right: 6%;
  //     animation: icons-triangle 4s linear infinite alternate;
  // }

  @keyframes icons-triangle {
    0% {
      transform: rotate(0deg) translateX(-50px);
    }

    100% {
      transform: rotate(360deg) translateY(100px);
    }
  }
}

#icons {
  .ball {
    position: absolute;
    border-radius: 100%;
    opacity: 0.7;
    z-index: -2;
  }
}
