.formBG {
  color: #d1e8e2;
  // background-image: linear-gradient(360deg, #091839 0%, #000000 74%);

  .mainFormBG {
    // background: #ffffff;
    padding: 1% 0 0 7%;

    .row {
      margin-right: 0;
    }

    input,
    textarea,
    .PhoneInput {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding: 0;
      background-color: transparent;
      color: #d1e8e2;
      //   box-shadow: 0 0 0 0.1rem rgba(209, 232, 226, 0.25);

      &:focus-visible,
      &:focus {
        outline: none;
      }
    }

    .form-control {
      &.is-invalid {
        &:focus-visible,
        &:focus {
          border-color: #dc3545;
          box-shadow: 0 0 0 0.1rem rgb(220 53 69 / 25%);
        }
        &.is-valid {
          border-color: #198754;
          box-shadow: 0 0 0 0.1rem rgb(25 135 84 / 25%);
        }
      }
    }

    .submitBtn {
      background: linear-gradient(180deg, #1e44d3 0%, #8d19da 100%);
    }

    .PhoneInput {
      display: -webkit-inline-box;

      &--focus {
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        &.is-valid {
          border-color: #198754;
          box-shadow: 0 0 0 0.1rem rgb(25 135 84 / 25%);
        }
        &.is-invalid {
          border-color: #dc3545;
          box-shadow: 0 0 0 0.1rem rgb(220 53 69 / 25%);
        }
      }

      &Input {
        border-bottom: 0;
        box-shadow: none;
      }
    }
    .invalid-feedback {
      height: 21px;
      display: block;
    }
  }
}
