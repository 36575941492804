.float-whatsapp {
  position: fixed;
  // top: 36px;
  text-align: center;
  z-index: 1;
  top: 50%;
  right: 0;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  // z-index: 1;
  .fa-whatsapp {
    //font-size: 55px;
    font-size: 60px;
    background-color: #73d073;
    // width: 49px;
    // height: 53px;
    width: 60px;
    height: 65px;
    color: white;
    padding: 4px;
    @media (max-width: 649px) {
      font-size: 40px;
      width: 40px;
      height: 45px;
    }
  }
}