.grid {
  display: block;
  //   flex-wrap: wrap;
  .small {
    width: 18px;
    height: 18px;
  }
  .circle,
  .square {
    @media (max-width: 992px) {
      margin: 1%;
    }
    margin: 1.8%;
    position: relative;
    width: 10px;
    height: 10px;
    pointer-events: none;
    position: relative;
    background-color: currentColor;
    font-size: 14px;
    color: #1dacb3 !important;
  }
  .circle {
    border-radius: 100%;
    &-hollow {
      border: 2px solid #1dacb3;
      color: transparent !important;
    }
  }
}
