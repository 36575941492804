.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  .toast-header {
    font-size: 18px;
    font-weight: 700;
    background-color: transparent;
    border-color: white;
    .notification {
      &-title {
        margin: 0;
        width: 100%;
      }
      &-image {
        width: 25px;
        height: 30px;
        margin-right: 12px;
        filter: brightness(0) invert(1);
        img {
          width: inherit;
        }
      }
    }
    .close {
      color: white;
    }
  }
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.5s ease-in-out;
  animation: toast-in-right 0.7s;
  @media (max-width: 760px) {
    right: 0;
    width: 100%;
    animation: toast-in-top-mobile 0.3s;
  }
}

.bottom-right {
  //   display: block !important;
  position: fixed;
  bottom: 12px;
  transition: transform 0.5s ease-in-out;
  &.show {
    right: 12px;
    animation: toast-in-right 0.7s;
    @media (max-width: 760px) {
      top: 0;
      z-index: 10000;
      right: 0;
      width: 100%;
      animation: toast-in-top-mobile 0.3s;
    }
  }
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.5s ease-in;
  animation: toast-in-left 0.7s;
  @media (max-width: 760px) {
    left: 0;
    width: 100%;
    animation: toast-in-top-mobile 0.3s;
  }
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.5s ease-in;
  animation: toast-in-left 0.7s;
  @media (max-width: 760px) {
    left: 0;
    width: 100%;
    animation: toast-in-top-mobile 0.3s;
  }
}

@keyframes toast-in-right {
  from {
    right: -100%;
  }

  75% {
    right: -50%;
  }

  to {
    right: 12px;
  }
}

@keyframes toast-in-right-mobile {
  from {
    right: -100%;
  }
  25% {
    right: -75%;
  }
  50% {
    right: -50%;
  }
  75% {
    right: -25%;
  }

  to {
    right: 0;
  }
}

@keyframes toast-out-right {
  from {
    right: 12px;
  }

  75% {
    right: -50%;
  }

  to {
    right: -100%;
  }
}

@keyframes toast-out-right-mobile {
  from {
    right: 0;
  }
  25% {
    right: -25%;
  }
  50% {
    right: -50%;
  }
  75% {
    right: -75%;
  }

  to {
    right: -100%;
  }
}

@keyframes toast-in-left {
  from {
    left: -100px;
  }

  75% {
    left: 6px;
  }

  to {
    left: 12px;
  }
}
@keyframes toast-in-left-mobile {
  from {
    left: -100%;
  }
  25% {
    left: -75%;
  }
  50% {
    left: -50%;
  }
  75% {
    left: -25%;
  }

  to {
    left: 0;
  }
}

@keyframes toast-in-top-mobile {
  from {
    top: -100%;
  }
  25% {
    top: -75%;
  }
  50% {
    top: -50%;
  }
  75% {
    top: -25%;
  }

  to {
    top: 0;
  }
}
