#portfolio {
  #prevButton,
  #nextButton {
    cursor: pointer;
    @media (max-width: 450px) {
      filter: invert(1);
    }
  }
  .portfolio-desc {
    text-align: center;
    &-click {
      font-style: italic;
      font-weight: 700;
    }
  }
}
