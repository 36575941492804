.whyus {
    display: grid;

    .drop {
        position: absolute;
        bottom: 100%;
        width: 10px;
        height: 120px;
        pointer-events: none;
        animation: drop 0.5s linear infinite;
    }

    @keyframes drop {
        0% {
            transform: translateY(0vh);
        }

        75% {
            transform: translateY(90vh);
        }

        100% {
            transform: translateY(90vh);
        }
    }


    .stem {
        width: 2px;
        height: 60%;
        margin-left: 7px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
        animation: stem 0.5s linear infinite;
    }

    @keyframes stem {
        0% {
            opacity: 1;
        }

        65% {
            opacity: 1;
        }

        75% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    .splat {
        width: 15px;
        height: 10px;
        border-top: 2px dotted rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        opacity: 1;
        transform: scale(0);
        animation: splat 0.5s linear infinite;
        display: none;
    }

    .splat {
        display: block;
    }

    @keyframes splat {
        0% {
            opacity: 1;
            transform: scale(0);
        }

        80% {
            opacity: 1;
            transform: scale(0);
        }

        90% {
            opacity: 0.5;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1.5);
        }
    }
}