@import "bootstrap/scss/bootstrap-utilities.scss";
@import url("../../../Fonts/fonts.scss");
nav {
  //   background-color: #6075a4;
  .row {
    width: 100%;
    // align-items: center;
  }
  .navbar-brand {
    width: 20%;

    img {
      width: 50%;

      @media (max-width: 767px) {
        width: 35%;
      }
    }
  }

  .navbar-toggler {
    color: white;
    border-color: white;
    width: 30px;
    height: 30px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus {
      box-shadow: none;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
      width: 20px;
      height: 20px;
      // margin-top: -8px;
    }
  }

  @media (max-width: 767px) {
    &.navbar {
      display: block;

      .navbar-nav {
        flex-direction: column;

        .nav-item {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

a.nav-link {
  color: #d1e8e2;
  text-decoration: none;
  &:hover,
  &:active {
    color: #ffcb9a !important;
    #logo-img {
      filter: brightness(0) saturate(100%) invert(86%) sepia(38%) saturate(670%)
        hue-rotate(313deg) brightness(103%) contrast(101%);
    }
  }
  &:focus {
    color: #d1e8e2;
  }
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(17, 100, 102, 0);
  transition: all 0.25s linear;
  .backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }
  #offcanvasNavbar {
    background-color: rgba(17, 100, 102, 1);
    .btn-dismiss {
      background-color: transparent;
      border: none;
      .nav-link {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  #logo {
    font-size: 30px;
    font-weight: bold;
    height: 50px;
    padding-top: 0;
    text-align: center;
    padding-right: 23%;
    font-family: IncompleetaRegular;
    letter-spacing: 7px;
    // @include media-breakpoint-up(sm) {
    //   padding-right: 33%;
    // }
    @include media-breakpoint-up(md) {
      padding-right: 17%;
    }
    @include media-breakpoint-down(md) {
      font-size: 25px;
      letter-spacing: 5px;
    }
    .nav-link {
      display: flex;
      justify-content: center;
    }
    &-img {
      margin-right: 5px;
      width: auto;
      height: 45px;
      @include media-breakpoint-up(md) {
        height: 150px;
      }
    }
  }
}
