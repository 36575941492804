#services {
  padding: 100px 0;
  padding-bottom: 0;
  .row {
    width: 100%;
    margin: 0;
  }
  .col-6 {
    padding: 28px 0;
  }
  .heading {
    font-size: 35px;
    line-height: 1.29;
    @media (min-width:424px){
      margin-top: 25px;
    }
  }
  span {
    display: inline-block;
    color: white;
  }
  .card {
    text-align: center;
    height: 585px;
    width: 95%;
    padding: 20px;
    border-radius: 30px;
    background: transparent;
    border: 5px solid #ffcb9a;
    margin-bottom: 50px;
    background: linear-gradient(to right, transparent 50%, #001819 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: all 2s ease;
    &:hover {
      background-position: right bottom;
      //   background-color: #001819;
      //   //   transition: all 2s ease-in-out;
      //   transition: all 1s cubic-bezier(0.58, 0, 0.06, 0.98);
      .desc,
      .heading {
        color: white;
        transition: all 2s ease-in-out;
      }
    }

    &-center {
      margin-top: 11%;
      //   display: flex;
      //   align-content: center;
      //   flex-wrap: wrap;
    }
    .desc {
      color: #d1e8e2;
      text-align: left;
      margin: auto;
    }
  }
}
