body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // height: 100% !important;
  // background-color: #091839 !important;
  background: radial-gradient(#116466, black);

  scroll-behavior: smooth;
}

// html {
//   height: 100% !important;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pl-120 {
  padding-left: 120px;
}

.ms-25 {
  margin-left: 25%;
}

@media (min-width: 1200px) {
  .offset-xla-1 {
    margin-left: 4%;
    margin-right: 4%;
  }
}

//page animation
// .page-enter {
//   opacity: 0.01;
//   transform: translateX(100%);
// }

// .page-enter.page-enter-active {
//   opacity: 1;
//   transform: translateX(0%);
//   transition: all 300ms ease-in;
// }

// .page-exit {
//   opacity: 1;
//   transform: translateX(0%);
// }

// .page-exit.page-exit-active {
//   opacity: 0.01;
//   transform: translateX(-100%);
//   transition: all 300ms ease-out;
// }
